import { atom } from 'jotai'
import {
  ActivityContact,
  CookieContact,
  ModelUser,
  Organization,
  User,
} from '~/models'

export const aUser = atom<User | null>(null)
export const aOriginalLoginUser = atom<ModelUser | null>(null)
export const aOrganization = atom<Organization | null>(null)

export const aListingContact = atom<ActivityContact | null>(null)
export const aCookieContact = atom<CookieContact | null>(null)
